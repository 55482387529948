import React from "react";

const EmployeeItem = (props) => {
  const { label, info ,className} = props;

  return (
    <div className={className ? className :`info_wrapper`}>
      <span style={{ color: "#9B9EAD",marginRight:"8px" }}>{label}</span>
      <span className={className} >{info}</span>
    </div>
  );
};

export default EmployeeItem;
